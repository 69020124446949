import React, { useState } from "react";
import Boss from "../domain/boss";
import Modal from "./layout/modal";

interface IProps {
  boss: Boss;
  onClose: () => void;
  onSave: (date: string, time: string) => void;
}

const KillModal: React.FunctionComponent<IProps> = (props) => {
  const [date, setDate] = useState<string>(
    new Date().toISOString().slice(0, 10)
  );
  const [time, setTime] = useState<string>("");

  const onDateChange = (e: React.FormEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;
    setDate(newValue);
  };

  const onTimeChange = (e: React.FormEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;
    setTime(newValue);
  };

  return (
    <div className="kill-modal">
      <Modal
        title={`Register time specific kill - ${props.boss.name} (${props.boss.map})`}
        onClose={props.onClose}
        onSave={() => props.onSave(date, time)}
        saveDisabled={!date || !time}
      >
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="date">Date</label>
              <input
                type="date"
                id="date"
                className="form-control"
                onChange={onDateChange}
                value={date || ""}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="time">Time</label>
              <input
                type="time"
                id="time"
                className="form-control"
                onChange={onTimeChange}
                value={time || ""}
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default KillModal;
