import BossKill from "./bossKill";

export default class Boss {
  bossID: number;
  mobID: number;
  name: string;
  spanMin: number;
  spanMax: number;
  bossKill?: BossKill;
  map: string;
  alive!: boolean;

  constructor(
    bossID: number,
    mobID: number,
    name: string,
    spanMin: number,
    spanMax: number,
    map: string,
    alive: boolean,
    bossKill?: BossKill
  ) {
    this.bossID = bossID;
    this.mobID = mobID;
    this.name = name;
    this.spanMin = spanMin;
    this.spanMax = spanMax;
    this.bossKill = bossKill;
    this.map = map;
    this.alive = alive;
  }
}
