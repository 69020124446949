export default class BossKill {
  bossKillID: number;
  bossID: number;
  dateKilled: Date;

  constructor(bossKillID: number, bossID: number, dateKilled: Date) {
    this.bossKillID = bossKillID;
    this.bossID = bossID;
    this.dateKilled = dateKilled;
  }
}
