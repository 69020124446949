import React, { useEffect, useState } from "react";
import BossStat from "../domain/bossStat";
import BossService from "../services/bossService";

export default function Stats() {
  const [stats, setStats] = useState<BossStat[]>([]);
  const [open, setOpen] = useState<number[]>([]);

  useEffect(() => {
    getStats();
  }, []);

  const getStats = () => {
    new BossService()
      .getStats()
      .then((stats) => setStats(stats))
      .catch(() => {});
  };

  const onToggleOpen = (bossID: number) => {
    const index = open.findIndex((_) => _ === bossID);

    if (index > -1) {
      const newArray = [...open];
      newArray.splice(index, 1);

      setOpen(newArray);
    } else {
      const newArray = [...open];
      newArray.push(bossID);
      setOpen(newArray);
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h2>Stats</h2>
        </div>
      </div>
      {stats &&
        !!stats.length &&
        stats.map((_) => {
          return (
            <div key={_.bossID} className="row mb-4">
              <div className="col-12">
                <div className="bosses d-flex justify-content-between">
                  <h4
                    className="pointer"
                    onClick={() => onToggleOpen(_.bossID)}
                  >
                    {_.name} ({_.map})
                  </h4>

                  <h4>{_.bossKills.length}</h4>
                </div>
              </div>
              {open.indexOf(_.bossID) > -1 &&
                _.bossKills.map((_) => {
                  return (
                    <div key={_.bossKillID} className="col-12">
                      <div className="bosses">
                        <span>{_.dateKilled}</span>
                      </div>
                    </div>
                  );
                })}
            </div>
          );
        })}
    </div>
  );
}
