import BossKill from "./bossKill";

export default class BossStat {
  bossID: number;
  mobID: number;
  name: string;
  map: string;
  bossKills: BossKill[] = [];

  constructor(
    bossID: number,
    mobID: number,
    name: string,
    map: string,
    bossKills: BossKill[]
  ) {
    this.bossID = bossID;
    this.mobID = mobID;
    this.name = name;
    this.map = map;
    this.bossKills = bossKills;
  }
}
