import Boss from "../domain/boss";
import BossKill from "../domain/bossKill";
import BossStat from "../domain/bossStat";
import RegisterKill from "../domain/request-models/registerKill";
import ApiService from "./apiService";

export default class BossService {
  getItems(): Promise<Boss[]> {
    return new Promise<Boss[]>((resolve, reject) => {
      new ApiService()
        .get("boss.php")
        .then((items) => {
          const mappedItems: Boss[] = [];

          if (Array.isArray(items) && !!items.length) {
            items.forEach((i) => {
              const bossKill = i.bossKill
                ? new BossKill(
                    i.bossKill.bossKillID,
                    i.bossKill.bossID,
                    new Date(Date.parse(i.bossKill.dateKilled))
                  )
                : undefined;

              const boss = new Boss(
                i.bossID,
                i.mobID,
                i.name,
                i.spanMin,
                i.spanMax,
                i.map,
                i.alive,
                bossKill
              );

              mappedItems.push(boss);
            });
          }

          resolve(mappedItems);
        })
        .catch(() => {
          reject();
        });
    });
  }

  registerKill(bossID: number, dateKilled?: Date): Promise<void> {
    const body = new RegisterKill(
      bossID,
      dateKilled ? this.dateToString(dateKilled) : ""
    );

    return new Promise<void>((resolve, reject) => {
      new ApiService()
        .post("kill.php", body)
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  }

  deleteBossKill(bossKillID: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      new ApiService()
        .post("delete.php", { bossKillID })
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  }

  getStats(): Promise<BossStat[]> {
    return new Promise<BossStat[]>((resolve, reject) => {
      new ApiService()
        .get("stats.php")
        .then((items) => {
          const mappedItems: BossStat[] = [];

          if (Array.isArray(items) && !!items.length) {
            items.forEach((i) => {
              mappedItems.push(
                new BossStat(
                  i.bossID,
                  i.mobID,
                  i.name,
                  i.map,
                  i.bossKills.map(
                    (k: any) =>
                      new BossKill(k.bossKillID, k.bossID, k.dateKilled)
                  )
                )
              );
            });
          }

          resolve(mappedItems);
        })
        .catch(() => {
          reject();
        });
    });
  }

  private dateToString(date: Date): string {
    // adjust 0 before single digit date
    let day = ("0" + date.getDate()).slice(-2);

    // current month
    let month = ("0" + (date.getMonth() + 1)).slice(-2);

    // current year
    let year = date.getFullYear();

    // current hours
    let hours = ("0" + date.getHours()).slice(-2);

    // current minutes
    let minutes = ("0" + date.getMinutes()).slice(-2);

    // current seconds
    let seconds = ("0" + date.getSeconds()).slice(-2);

    return (
      year +
      "-" +
      month +
      "-" +
      day +
      " " +
      hours +
      ":" +
      minutes +
      ":" +
      seconds
    );
  }
}
