import React, { useEffect, useState } from "react";
import Boss from "../domain/boss";
import BossService from "../services/bossService";
import KillModal from "./killModal";

export default function Bosses() {
  const [bosses, setBosses] = useState<Boss[]>([]);
  const [filter, setFilter] = useState<string>("");
  const [bossToRegister, setBossToRegister] = useState<Boss | undefined>(
    undefined
  );

  useEffect(() => {
    const interval = setInterval(() => {
      getBosses();
    }, 30 * 1000);

    getBosses();
    return () => clearInterval(interval);
  }, []);

  const getBosses = () => {
    new BossService()
      .getItems()
      .then((bosses) => setBosses(bosses))
      .catch(() => {});
  };

  const renderStatus = (boss: Boss) => {
    if (!boss.bossKill) {
      return <span className="green">Alive.</span>;
    }

    const killed = `Killed ${boss.bossKill.dateKilled
      .toLocaleTimeString()
      .substring(0, 5)}.`;

    const spawnMin = new Date(
      boss.bossKill.dateKilled.getTime() + boss.spanMin * 60000
    );

    const spawnMax = new Date(
      boss.bossKill.dateKilled.getTime() + boss.spanMax * 60000
    );

    const spawns = ` Spawns ${spawnMin
      .toLocaleTimeString()
      .substring(0, 5)}~${spawnMax.toLocaleTimeString().substring(0, 5)}.`;

    return [
      <span className="red" key="killed">
        {killed}
      </span>,
      <span className="green" key="spawns">
        {spawns}
      </span>,
    ];
  };

  const renderActions = (boss: Boss) => {
    const actions: JSX.Element[] = [
      <i
        key="register"
        onClick={() => registerKill(boss.bossID)}
        title="Register kill"
        className="fas fa-skull-crossbones pointer"
      ></i>,
      <i
        key="register-specific"
        onClick={() => openRegisterModal(boss.bossID)}
        title="Register time specific kill"
        className="fas fa-history pointer"
      ></i>,
    ];

    const bossKill = boss.bossKill;
    if (bossKill) {
      actions.push(
        <i
          key="delete"
          onClick={() => deleteBossKill(bossKill.bossKillID)}
          title="Delete registered kill"
          className="fas fa-trash-alt pointer"
        ></i>
      );
    }

    return actions;
  };

  const registerKill = (
    bossID: number,
    dateKilled?: Date,
    successCallback?: () => void
  ) => {
    new BossService()
      .registerKill(bossID, dateKilled)
      .then(() => {
        getBosses();

        if (successCallback) {
          successCallback();
        }
      })
      .catch(() => {});
  };

  const deleteBossKill = (bossKillID: number) => {
    new BossService()
      .deleteBossKill(bossKillID)
      .then(() => {
        getBosses();
      })
      .catch(() => {});
  };

  const onFilterChange = (e: React.FormEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;
    setFilter(newValue);
  };

  const applyFilter = (bosses: Boss[]) => {
    if (!filter) {
      return bosses;
    }

    return bosses.filter((b) =>
      b.name.toLowerCase().includes(filter.toLowerCase())
    );
  };

  const openRegisterModal = (bossID: number) => {
    setBossToRegister(bosses.find((b) => b.bossID === bossID));
  };

  const closeRegisterModal = () => {
    setBossToRegister(undefined);
  };

  const registerSpecificKill = (date: string, time: string) => {
    if (bossToRegister) {
      const dateKilled = new Date(Date.parse(`${date} ${time}`));
      registerKill(bossToRegister.bossID, dateKilled, () => {
        setBossToRegister(undefined);
      });
    }
  };

  const renderBossList = (aliveList = false, filteredBosses: Boss[]) => {
    return (
      <div className="row mt-4">
        <div className="col-md-12">
          <div className="bosses">
            <div className="row">
              <div className="col-12">
                <h4>{aliveList ? "Alive" : "Dead"}</h4>
              </div>
              <div className="col-md-12">
                <div className="header">
                  <div className="row">
                    <div className="col-md-3">
                      <span>Boss</span>
                    </div>
                    <div className="col-md-2">
                      <span>Spawn</span>
                    </div>
                    <div className="col-md-5">
                      <span>Status</span>
                    </div>
                    <div className="col-md-2">
                      <span className="text-center">Actions</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {applyFilter(filteredBosses).map((b) => (
              <div key={b.bossID} className="row">
                <div className="col-md-12">
                  <div className="boss">
                    <div className="row">
                      <div className="col-md-3 my-auto">
                        <div className="name">
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={`https://ratemyserver.net/mob_db.php?mob_id=${b.mobID}&small=1&back=1`}
                          >
                            {b.name}
                          </a>
                          <a
                            className="map-link"
                            target="_blank"
                            rel="noreferrer"
                            href={`https://ratemyserver.net/npc_shop_warp.php?map=${b.map}&s_block=mob_block&small=1`}
                          >
                            {b.map}
                          </a>
                        </div>
                      </div>
                      <div className="col-md-2 my-auto">
                        <span>{`${b.spanMin}~${b.spanMax} min`}</span>
                      </div>
                      <div className="col-md-5 my-auto">{renderStatus(b)}</div>
                      <div className="col-md-2 my-auto">
                        <div className="actions">{renderActions(b)}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                placeholder="Filter bosses ..."
                onChange={onFilterChange}
                value={filter || ""}
              />
            </div>
          </div>
        </div>

        {renderBossList(false, [
          ...bosses
            .filter((_) => !_.alive)
            .sort((a: Boss, b: Boss) => {
              if (!a.bossKill) {
                return -1;
              }

              if (!b.bossKill) {
                return 1;
              }

              return a.bossKill.dateKilled.getTime() + a.spanMin * 60000 >
                b.bossKill.dateKilled.getTime() + b.spanMin * 60000
                ? 1
                : -1;
            }),
        ])}
        {renderBossList(
          true,
          bosses.filter((_) => !!_.alive)
        )}
      </div>
      {bossToRegister && (
        <KillModal
          boss={bossToRegister}
          onClose={closeRegisterModal}
          onSave={(date: string, time: string) =>
            registerSpecificKill(date, time)
          }
        />
      )}
    </>
  );
}
