export default class ApiService {
  private urlPrefix: string = "";

  constructor() {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      // dev code
      this.urlPrefix = "http://localhost/mvp-timer/API/";
    } else {
      // production code
      this.urlPrefix = "/API/";
    }
  }

  get(url: string) {
    return new Promise<any>((resolve, reject) => {
      fetch(`${this.urlPrefix + url}`, this.getOptions("GET"))
        .then((response) => response.json())
        .then((json) => resolve(json))
        .catch(() => reject());
    });
  }

  post(url: string, body?: object) {
    return new Promise<void>((resolve, reject) => {
      fetch(`${this.urlPrefix + url}`, this.getOptions("POST", body))
        .then(() => {
          resolve();
        })

        .catch(() => reject());
    });
  }

  //////////

  private getOptions(method: string, body?: object) {
    const headers = new Headers({ "Content-Type": "application/json" });

    const options = {
      method: method,
      withCredentials: true,
      headers: headers,
      body: body ? JSON.stringify(body) : null,
    };

    return options;
  }
}
