import React from "react";

interface IProps {
  title: string;
  onClose: () => void;
  onSave: () => void;
  saveDisabled?: boolean;
}

const Modal: React.FunctionComponent<IProps> = (props) => (
  <>
    <div className="custom-modal">
      <div className="header">
        <h4>{props.title}</h4>
        <i className="fas fa-times pointer" onClick={props.onClose}></i>
      </div>
      <div className="body">{props.children}</div>
      <div className="footer">
        <button
          className="btn btn-primary"
          onClick={props.onSave}
          disabled={props.saveDisabled}
        >
          Save
        </button>
      </div>
    </div>
    <div className="overlay" onClick={props.onClose}></div>
  </>
);

export default Modal;
