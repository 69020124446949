import React from "react";
import "./styling/app.scss";
import Bosses from "./components/bosses";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Stats from "./components/stats";

function App() {
  return (
    <div className="app">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h3 className="text-center">ÖSB's MVP timer</h3>
          </div>
        </div>
      </div>

      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Bosses />} />
          <Route path="stats" element={<Stats />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
